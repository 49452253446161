.video-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.video-container h2 {
    color: rgb(0, 0, 60);
    margin-bottom: 18px;
}

.video-container .video-tag {
    width: 100%;
    border-radius: 12px;
    -webkit-box-shadow: 0px 0px 15px 8px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 8px rgba(0,0,0,0.1);
}