.account {
    display: flex;
    flex-direction: row;

    width: 100%;
    background-color: transparent;
}

.account .page-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
}

.account .page-right .account__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
    width: 100%;
    height: 30vh;
}

.account .page-right .account__container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 34px;
    width: 90%;
}

.account .page-right .account__container .account-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 44px;
    background-color: rgba(245, 249, 255, 0.562);
    width: 50%;
    border-radius: 2px;
    border: 1px solid rgb(87, 118, 255);
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
}

.account .page-right .account__container .account-form .info {
    margin-top: 18px;
    width: 100%;
    font-size: 16px;
    padding-bottom: 5px;
    margin-bottom: 8px;
    border-bottom: 1px dashed rgba(87, 118, 255, 0.37);
}

.account .page-right .account__container .account-form .double-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.account .page-right .account__container .account-form .single-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.account .page-right .account__container .account-form .single-container .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.account .page-right .account__container .account-form .single-container .input-container input {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    border-radius: 10px;
    outline: 2px solid rgb(255, 210, 97);
    border: 0;
    background-color: #ebebeb;
    outline-offset: 3px;
    padding: 10px 1rem;
    transition: 0.25s;
}

.account .page-right .account__container .account-form .double-container .input-container {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.account .page-right .account__container .account-form .double-container .input-container input {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    border-radius: 10px;
    outline: 2px solid rgb(255, 210, 97);
    border: 0;
    background-color: #ebebeb;
    outline-offset: 3px;
    padding: 10px 1rem;
    transition: 0.25s;
}

.account .page-right .account__container .account-form .double-container .input-container input:focus {
    outline-offset: 5px;
    background-color: #fff
}

.account .page-right .account__container .account-form .info {
    font-size: 18px;
}

.account .page-right .account__container .card-form {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 44px;
    background-color: rgba(245, 249, 255, 0.562);

    border-radius: 2px;
    border: 1px solid rgb(87, 118, 255);
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
}