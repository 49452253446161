.code-card {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid rgb(0, 68, 255);
  font-size: 14px;
  font-family: monospace;
  overflow: auto;
  margin-top: 28px;
}

.titlebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 21px;
  font-weight: 450;
  background-color: rgb(0, 68, 255);
  width: 100%;
  text-align: right;
}

.titlebar .titlebar-left {
  padding-left: 8px;
}

.titlebar .titlebar-left p {
  font-size: 24px;
  color: white;
}

.code-card button {
  width: 40px;
  height: 35px;
  margin-left: -5px;
  border: 0;
  outline: 0;
  background: transparent;
  transition: 0.2s;
}

button svg path, 
button svg rect, 
button svg polygon {
  fill: #ffffff;
}

button svg {
  width: 10px;
  height: 10px;
}

.close:hover {
  background-color: #e81123;
}

.maximize:hover {
  background-color: #c042ff2e;
}

.minimize:hover {
  background-color: #c042ff2e;
}


.code-card .code {
    padding: 8px;
}

/* 
  Palette you provided:
  1) #ff0000
  2) #e600ff
  3) #ffffff
  4) #ffff00
  5) #22ff00
  6) #4281ff
  7) #ff4284
  8) #ffae00
  9) #ffffff (repeat)
  10) #ffff00 (repeat)
*/

.comment {
  /* A darker green for comments */
  color: #00cc00;
}

.keyword {
  /* Vibrant purple for keywords */
  color: #db005b;
}

.boolean {
  /* Dark blue for booleans (true/false) */
  color: goldenrod;
}

.number {
  /* Medium green for numbers */
  color: #098658;
}

.operator {
  /* Reddish-brown for operators (=, +, -, etc.) */
  color: #A31515;
}

.punctuation {
  /* Dark gray for punctuation (., (), etc.) */
  color: #000000;
}

.builtin {
  /* Medium blue for built-in objects (script, math, CFrame, wait) */
  color: #005CC5;
}

.function {
  /* Brownish color for function calls (Angles, rad, etc.) */
  color: #795E26;
}

.property {
  /* Teal for property references (beam.CFrame, .Parent, etc.) */
  color: #02b3e9;
}

.identifier {
  /* Dark gray for variable names (beam, rotationSpeed) */
  color: #2E2E2E;
}
