.copybox {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.copybox h2 {
    font-size: 26px;
    font-weight: 500;
}

.copybox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 44px;
}

.copybox-container .copybox-button {
    padding: 10px 20px;
    width: 30%;
    height: 100%;
    color: #fff;
    background-color: rgb(0, 68, 255);
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
}

.copybox-container .copybox-text {
    height: 100%;
    width: 70%;
    padding-left: 8px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 18px;
    color: #555;
    border: 1px solid black;
    overflow-x: hidden;
}