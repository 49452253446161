.reschedule-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px;
}

.reschedule-container table {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 2px solid rgb(0, 0, 80);
    /* Add left border to the table itself */
}

.reschedule-container table tr {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}

.reschedule-container table th {
    background-color: rgb(0, 0, 80);
    color: white;
}

.reschedule-container table th,
.reschedule-container table td {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 34%;
    padding: 8px;
    border-top: 2px solid rgb(0, 0, 80);
    /* Top border for all */
    border-left: 2px solid rgb(0, 0, 80);
    /* Left border for all */
}

.reschedule-container table td p {
    font-weight: 700;
    margin-top: 4px;
}

/* Remove left border from first cell in each row to avoid overlap with table’s left border */
.reschedule-container table tr th:first-child,
.reschedule-container table tr td:first-child {
    border-left: none;
}

/* Add right border only to the last cell in each row */
.reschedule-container table tr th:last-child,
.reschedule-container table tr td:last-child {
    border-right: 2px solid rgb(0, 0, 80);
}

/* Add bottom border only to the last row */
.reschedule-container table tr:last-child th,
.reschedule-container table tr:last-child td {
    border-bottom: 2px solid rgb(0, 0, 80);
}

@media screen and (max-width: 1250px) {
    .reschedule-container {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .reschedule-container .cancelled-class {
        display: none;
    }

    .reschedule-container table th,
    .reschedule-container table td {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 50%;
        padding: 8px;
        border-top: 2px solid rgb(0, 0, 80);
        /* Top border for all */
        border-left: 2px solid rgb(0, 0, 80);
        /* Left border for all */
    }
}