.card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 20px;
}

.card-container .current-card {
    padding: 15px;
    background: rgb(0,0,60);
    border-radius: 4px;

    -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
}

.card-container .current-card h3 {
    color: #e6e6e6;
    margin-bottom: 12px;
    font-size: 18px;
}

.card-container .current-card p {
    color: #e6e6e6;
    margin-bottom: 4px;
}

.card-container .card-input {
    width: 100%;

    margin-top: 20px;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    background: #fff;
}

.card-container .card-input h3 {
    margin-bottom: 12px;
}

.card-container .card-input .update-button {
    width: 100%;
    height: 34px;
    margin-top: 14px;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 15px;
    background-color: #006aff;

    cursor: pointer;
    transition: 0.2s all ease-in;
}

.card-container .card-input .update-button:hover {
    background-color: #0050bf;
}