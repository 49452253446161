.robloxGames__container {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #fff;
    padding-top: 38px;
    padding-bottom: 38px;
    padding-left: 92px;
    padding-right: 92px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.robloxGames__container .step__selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    background-color: rgb(0, 0, 85);
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 50px;
    margin-top: 18px;
}

.robloxGames__container .step__selector .arrow__icon {
    color: white;
    cursor: pointer;
}


.robloxGames__container .fs__step__container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 12px;
    margin-top: 42px;
    width: 100%;
    -webkit-box-shadow: 2px 1px 50px 10px rgba(0,0,0,0.15); 
    box-shadow: 2px 1px 50px 10px rgba(0,0,0,0.15);
}

.robloxGames__container .fs__step__container .demo__image {
    width: 80%;
    border-radius: 15px;
    margin-top: 44px;
    margin-bottom: 16px;
    -webkit-box-shadow: 2px 1px 50px 10px rgba(0,0,0,0.15); 
    box-shadow: 2px 1px 50px 10px rgba(0,0,0,0.15);
    align-self: center;
}


.robloxGames__container .step__number {
    display: flex;
    flex-direction: row;
    width: 20%;
    background-color: black;
    height: 34px;
}

.robloxGames__container .step__number .oper__cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 20%;
    cursor: pointer;
}

.robloxGames__container .step__number .oper__cont .operator {
    color: #fff;
}

.robloxGames__container .step__number input {
    font-size: 18px;
    padding-left: 7px;
    width: 60%;
}

.robloxGames__container h1 {
    text-align: center;
    align-self: center;
    font-size: 32px;
    margin-bottom: 12px;
}

.robloxGames__container .desc {
    text-align: center;
    align-self: center;
    font-size: 28px;
}

.robloxGames__container .intro {
    text-align: center;
    margin-top: 28px;
    margin-bottom: 28px;
}

.robloxGames__container h3 {
    margin-top: 22px;
    margin-bottom: 28px;
}

.robloxGames__container p {
    font-size: 22px;
}

.robloxGames__container h2 {
    font-size: 26px;
    margin-top: 32px;
    margin-bottom: 22px;
}

.robloxGames__container ul {
    margin-left: 32px;
}

.robloxGames__container ul li {
    font-size: 22px;
    margin-bottom: 12px;
}

.robloxGames__container .code-box {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    overflow-x: auto;
    font-family: "Courier New", Courier, monospace;
    margin-top: 22px;
    margin-bottom: 22px;
}



.robloxGames__container .luaExample {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    overflow-x: auto;
    font-family: "Courier New", Courier, monospace;
    margin-top: 22px;
    margin-bottom: 22px;
    font-size: 20px;
}

.robloxGames__container .projectButtonFilesContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}


/* NEW TUTORIAL STYLING */
.roblox-tutorial {
    width: 100%;
    min-height: 90vh;
    
    font-family: Arial, Helvetica, sans-serif;
    background-color: #fff;
    padding-top: 38px;
    padding-bottom: 38px;
    padding-left: 72px;
    padding-right: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background-image: url("../../interface-images/lines.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.roblox-tutorial .loadcontainer {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.roblox-tutorial .tutorial-structure {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.roblox-tutorial .tutorial-structure .tutorial-structure-left {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.roblox-tutorial .tutorial-structure .tutorial-structure-right {
    width: 28%;
    height: 595px;
    border-radius: 12px;
    overflow-y: scroll;       /* Still scrollable */
    border: 1px solid rgb(67, 102, 255);

    /* Hide scrollbar (cross-browser) */
    /* For Internet Explorer/Edge (Legacy) */
    -ms-overflow-style: none;

    /* For Firefox */
    scrollbar-width: none;
    -webkit-box-shadow: 0px 0px 15px 8px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 15px 8px rgba(0,0,0,0.1);
}

/* For Chrome, Safari, and newer Edge (Chromium) */
.roblox-tutorial .tutorial-structure .tutorial-structure-right::-webkit-scrollbar {
    width: 0;
    display: none;
}


.roblox-tutorial .tutorial-structure .tutorial-structure-right .project-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 12px;
    background-color: rgb(255, 203, 70);
    border-bottom: 1px solid rgb(0, 68, 255);
    height: 60px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.roblox-tutorial .tutorial-structure .tutorial-structure-right .project-name h3 {
    color: rgb(0, 68, 255);
    font-size: 22px;
}

.roblox-tutorial .tutorial-structure .tutorial-structure-right .tutorial-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 8px;
    background-color: white;
    cursor: pointer;
    transition: 0.2s all ease-in;
    padding-right: 4px;
}

.roblox-tutorial .tutorial-structure .tutorial-structure-right .tutorial-card:hover {
    background-color: rgb(228, 228, 228);
}

.roblox-tutorial .tutorial-structure .tutorial-structure-right .tutorial-card:hover {
    background-color: rgb(228, 228, 228);
}

.roblox-tutorial .tutorial-structure .tutorial-structure-right .tutorial-card.selected {
    background-color: rgb(228, 228, 228);
}

.roblox-tutorial .tutorial-structure .tutorial-structure-right .tutorial-card .tutorial-card-left {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
}

.roblox-tutorial .tutorial-structure .tutorial-structure-right .tutorial-card .tutorial-card-right {
    width: 90%;
    display: flex;
    flex-direction: row;
}

.roblox-tutorial .tutorial-structure .tutorial-structure-right .tutorial-card .tutorial-card-right .video-preview {
    width: 40%;
    margin-right: 7px;
    border-radius: 8px;
}


.roblox-tutorial .tutorial-structure .tutorial-structure-right .tutorial-card .tutorial-card-right .tutorial-name {
    font-size: 14px;
    font-weight: 600;
}