.oops-page {
    display: flex;
    flex-direction: row;

    width: 100%;

    background-color: transparent;
    background-image: url("../../interface-images/lines.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.oops-page .oops-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
    height: 100%;
}

.oops-page .oops-container .oops-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
    width: 100%;
    height: 30vh;
}

.oops-page .oops-container .oops {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
    width: 80%;
}

.oops-page .oops-container .oops h1 {
    font-size: 72px;
    font-weight: 300;
    letter-spacing: 4px;
    margin-bottom: 18px;
}

.oops-page .oops-container .oops h2 {
    font-size: 34px;
    font-weight: 700;
    width: 80%;
    text-align: center;
}

.oops-page .oops-container .oops h2 span {
    color: red;
}

.oops-page .oops-container .oops .get-help-form {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 50%;
    margin-top: 40px;
    padding: 44px;
    border-radius: 4px;
    margin-bottom: 120px;

    -webkit-box-shadow: 2px 2px 17px 8px rgba(0, 0, 0, 0.07);
    box-shadow: 2px 2px 17px 8px rgba(0, 0, 0, 0.07);
}

.oops-page .oops-container .oops .get-help-form .note {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 700;
}

.oops-page .oops-container .oops .get-help-form textarea {
    padding: 12px;
    font-size: 18px;
    width: 100%;
    height: 240px;
    border: 2px solid gold;
    border-radius: 2px;
    margin-bottom: 18px;
}


.oops-page .oops-container .oops .get-help-form .animButton {
    width: 100%;
    --black-700: #121FCF;
    --border_radius: 8px;
    --transtion: 0.3s ease-in-out;
    --offset: 2px;

    cursor: pointer;
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    transform-origin: center;

    padding: 1rem 2rem;
    background-color: transparent;

    border: none;
    border-radius: var(--border_radius);
    transform: scale(calc(1 + (var(--active, 0) * 0.1)));

    transition: transform var(--transtion);
}

.oops-page .oops-container .oops .get-help-form .animButton::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;
    background-color: var(--black-700);
    /* Blue background */

    border-radius: var(--border_radius);
    box-shadow: inset 0 0.5px hsl(0, 0%, 100%), inset 0 -1px 2px 0 #121FCF,
        0px 4px 10px -4px hsla(0 0% 0% / calc(1 - var(--active, 0))),
        0 0 0 calc(var(--active, 0) * 0.375rem) hsla(228, 97%, 50%, 0.75);
    /* Blue color */

    transition: all var(--transtion);
    z-index: 0;
}

.oops-page .oops-container .oops .get-help-form .animButton::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;

    background-color: hsla(228, 97%, 50%, 0.75);
    background-image: radial-gradient(at 51% 89%,
            hsla(228, 45%, 60%, 1) 0px,
            /* Blue gradient */
            transparent 50%),
        radial-gradient(at 100% 100%, hsla(228, 36%, 50%, 1) 0px, transparent 50%),
        radial-gradient(at 22% 91%, hsla(228, 36%, 50%, 1) 0px, transparent 50%);
    background-position: top;

    opacity: var(--active, 0);
    border-radius: var(--border_radius);
    transition: opacity var(--transtion);
    z-index: 2;
}

.oops-page .oops-container .oops .get-help-form .animButton:is(:hover, :focus-visible) {
    --active: 1;
    background-color: #121FCF;
    box-shadow: 0 0 0 calc(var(--active, 0) * 0.375rem) hsla(228, 97%, 50%, 0.75);
    /* Blue border shadow */
}


.oops-page .oops-container .oops .get-help-form .animButton:active {
    transform: scale(1);
}

.oops-page .oops-container .oops .get-help-form .animButton .dots_border {
    --size_border: calc(100% + 2px);

    overflow: hidden;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: var(--size_border);
    height: var(--size_border);
    background-color: transparent;

    border-radius: var(--border_radius);
    z-index: -10;
}



.oops-page .oops-container .oops .get-help-form .animButton .dots_border::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left;
    transform: rotate(0deg);

    width: 100%;
    height: 2rem;
    background: linear-gradient(90deg,
            rgba(255, 0, 150, 0.8) 0%,
            /* Pinkish color */
            rgba(0, 128, 255, 0.8) 50%,
            /* Blue color */
            rgba(0, 255, 128, 0.8) 100%
            /* Greenish color */
        );
    box-shadow: 0 0 10px rgba(255, 0, 150, 0.5),
        0 0 20px rgba(0, 128, 255, 0.5),
        0 0 30px rgba(0, 255, 128, 0.5);
    mask: linear-gradient(transparent 0%, white 120%);
    animation: rotate 2s linear infinite;
}




@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.oops-page .oops-container .oops .get-help-form .animButton .sparkle {
    position: relative;
    z-index: 10;
    width: 24px;
    height: 24px;
}

.oops-page .oops-container .oops .get-help-form .animButton .sparkle .path {
    fill: currentColor;
    stroke: currentColor;

    transform-origin: center;

    color: hsl(0, 0%, 100%);
}

.oops-page .oops-container .oops .get-help-form .animButton:is(:hover, :focus) .sparkle .path {
    animation: path .5s linear 0.5s infinite;
}

.oops-page .oops-container .oops .get-help-form .animButton .sparkle .path:nth-child(1) {
    --scale_path_1: 1.2;
}

.oops-page .oops-container .oops .get-help-form .animButton .sparkle .path:nth-child(2) {
    --scale_path_2: 1.2;
}

.oops-page .oops-container .oops .get-help-form .animButton .sparkle .path:nth-child(3) {
    --scale_path_3: 1.2;
}

@keyframes path {

    0%,
    34%,
    71%,
    100% {
        transform: scale(1);
    }

    17% {
        transform: scale(var(--scale_path_1, 1));
    }

    49% {
        transform: scale(var(--scale_path_2, 1));
    }

    83% {
        transform: scale(var(--scale_path_3, 1));
    }
}

.oops-page .oops-container .oops .get-help-form .animButton .text_button {
    position: relative;
    z-index: 10;

    background-image: linear-gradient(90deg,
            hsla(0 0% 100% / 1) 100%,
            hsla(0 0% 100% / var(--active, 0)) 120%);

    background-clip: text;

    font-size: 1rem;
    font-weight: 700;
    color: transparent;
}


@media screen and (max-width: 1100px) {
    .oops-page .oops-container .oops .get-help-form {
        width: 80%;
    }
}


@media screen and (max-width: 1000px) {
    .oops-page .oops-container .oops h1 {
        font-size: 44px;
        font-weight: 300;
        letter-spacing: 4px;
        margin-bottom: 18px;
    }
    
    .oops-page .oops-container .oops h2 {
        font-size: 28px;
        font-weight: 700;
        width: 80%;
        text-align: center;
    }
}

@media screen and (max-width: 720px) {
    .oops-page .oops-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;
        min-height: 100vh;
        height: 100%;
    }

    .oops-page .oops-container .oops {
        padding-right: 22px;
    }

    .oops-page .oops-container .oops h2 {
        font-size: 28px;
        font-weight: 700;
        width: 100%;
        text-align: center;
    }

    .oops-page .oops-container .oops .get-help-form {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .oops-page .oops-container .oops h2 {
        font-size: 20px;
        font-weight: 700;
        width: 100%;
        text-align: center;
    }
}


@media screen and (max-width: 500px) {
    .oops-page .oops-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        min-height: 100vh;
        height: 100%;
    }

    .oops-page .oops-container .oops {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 30px;
        padding-right: 0;
        width: 100%;
    }

    .oops-page .oops-container .oops h1 {
        font-size: 28px;
        font-weight: 300;
        letter-spacing: 4px;
        margin-bottom: 18px;
    }

    .oops-page .oops-container .oops h2 {
        font-size: 18px;
        font-weight: 700;
        width: 90%;
        text-align: center;
    }
}