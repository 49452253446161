.attendance__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 14px;
    background-color: rgba(0, 0, 0, 0.281);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 10; 
}


.attendance__container .attendance__form {
    background-color: #fff;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 22px;
    border-radius: 8px;
    border: 1px solid blue;
}

.attendance__container .attendance__form .attendance__icon__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.attendance__container .attendance__form .attendance__icon__container .close-button {
    cursor: pointer;
    color: red;
}

.attendance__container .attendance__form h1 {
    font-size: 28px;
}


.attendance__container .attendance__form .detailsPath__container {
    width: 100%;
}

.attendance__container .attendance__form .detailsPath__container .certificateName {
    margin-left: 28px;
    align-items: center;
}


.attendance__container .attendance__form .detailsPath__container .empty__projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 2px solid black;
    height: 150px;
    margin-bottom: 120px;
}


.attendance__container .attendance__form .detailsPath__container ul li {
    margin-bottom: 12px;
    font-family: 18px;
}


.attendance__container .attendance__form .detailsPath__container .empty__projects p {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}

.attendance__container .attendance__form .pathSelect {
    height: 34px;
    font-size: 18px;
    width: 100%;
    margin-bottom: 32px;
}

.attendance__container .attendance__form .textAreaNotes {
    width: 100%;
    height: 50%;
    margin-bottom: 18px;
    padding: 9px;
    font-size: 16px;
}



.attendance__container .attendance__form .options__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
}

.attendance__container .attendance__form .options__container select {
    height: 32px;
    font-weight: 18px;
    margin-bottom: 18px;
    font-size: 18px;
}

.attendance__container .attendance__form .project-selector {
    height: 32px;
    font-weight: 18px;
    margin-bottom: 18px;
    font-size: 18px;
    width: 100%;
}

.attendance__container .attendance__form .submitNotes {
    width: 100%;
    height: 34px;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.attendance__container .attendance__form .submitNotes:hover {
    background-color: rgb(192, 0, 0);
}


.attendance__container .attendance__form .awardCertificate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 22px;
}

.attendance__container .attendance__form .awardCertificate-container .certSelector {
    width: 100%;
    height: 34px;
    margin-bottom: 18px;
    font-size: 18px;
    padding: 7px;
}

.attendance__container .attendance__form .awardCertificate-container .certButton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 34px;
    color: white;
    background-color: rgb(214, 192, 26);
    align-self: center;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.attendance__container .attendance__form .awardCertificate-container .certButton:hover {
    background-color: rgb(168, 151, 19);
}

@media screen and (max-width: 1200px) {
    .attendance__container .attendance__form {
        width: 70%;
    }
}

@media screen and (max-width: 800px) {
    .attendance__container .attendance__form {
        width: 90%;
    }
}

@media screen and (max-width: 500px) {
    .attendance__container .attendance__form {
        width: 100%;
        padding: 5px;
    }
}