.reschedule-page {
    display: flex;
    flex-direction: row;

    width: 100%;
    background-color: transparent;
}

.reschedule-page .reschedule-page-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
}

.reschedule-page .reschedule-page-container .reschedule__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
    width: 100%;
    height: 30vh;
}

.reschedule-page .reschedule-page-container .reschedule {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 34px;
    width: 90%;
}

.reschedule-page .reschedule-page-container .reschedule .top-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 70%;
    margin-bottom: 34px;
}

.reschedule-page .reschedule-page-container .reschedule .top-container .back-button {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 22px;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    transition: 0.2s all ease-in;
    width: 20%;
}

.reschedule-page .reschedule-page-container .reschedule .top-container .back-button:hover {
    color: rgb(0, 0, 225);
}

.reschedule-page .reschedule-page-container .reschedule .top-container .left {
    display: flex;
    flex-direction: column;
}

.reschedule-page .reschedule-page-container .reschedule .top-container .left h1 {
    font-weight: 600;
    margin-bottom: 10px;
}

.reschedule-page .reschedule-page-container .reschedule .top-container .left h2 {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 34px;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 14px;
    width: 70%;
    border-radius: 4px;
    background-color: #006aff;
    border: 4px solid #c0dfff;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card p {
    color: white;
    font-size: 22px;
    font-weight: 600;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card button {
    height: 38px;
    width: 50%;
    font-weight: 600;
    font-size: 20px;
    border-radius: 4px;
    background-color: #ffcc00;
    border: 2px solid #cc9900;
    color: #432e23;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card button:hover {
    background-color: #cfa600;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .selector-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .selector-container h3 {
    margin-bottom: 12px;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .selector-container select {
    width: 30%;
    height: 34px;
    font-size: 18px;
    padding-left: 4px;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
    width: 70%;
    border-radius: 4px;
    background-color: #006aff;
    border: 4px solid #c0dfff;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card p {
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card button {
    height: 38px;
    width: 50%;
    font-weight: 500;
    font-size: 20px;
    border-radius: 4px;
    background-color: #ffcc00;
    border: 2px solid #cc9900;
    color: #432e23;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card button:hover {
    background-color: #cfa600;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .notimeslot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 240px;
    width: 70%;

    border: 1.5px solid rgb(145, 145, 145);
    border-radius: 2px;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .notimeslot-container p {
    font-size: 18px;
    text-decoration: underline;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
    width: 55%;
    border-radius: 4px;
    background-color: white;
    -webkit-box-shadow: 2px 2px 17px 8px rgba(0,0,0,0.07); 
    box-shadow: 2px 2px 17px 8px rgba(0,0,0,0.07);
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card p {
    font-size: 18px;
    font-weight: 500;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card button {
    width: 20%;
    height: 34px;
    border-radius: 4px;
    background-color: #006aff;
    border: none;
    color: white;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card button:hover {
    background-color: #005fe4;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 300px;
    padding: 32px;
    -webkit-box-shadow: 2px 2px 17px 8px rgba(0,0,0,0.07); 
    box-shadow: 2px 2px 17px 8px rgba(0,0,0,0.07);
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form .form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 18px;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form .form-container p {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid rgb(187, 187, 187);
    width: 100%;
    margin-bottom: 8px;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form .form-container .input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form .form-container .input-container input {
    width: 49%;
    height: 38px;
    font-size: 18px;
    padding-left: 4px;
    font-weight: 500;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form button {
    width: 100%;
    height: 34px;
    background-color: #006aff;
    color: white;
    border: none;
    font-size: 18px;
    border-radius: 2px;
    margin-top: 24px;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form button:hover {
    background-color: #005fe4;
}



@media screen and (max-width: 1080px) {
    .reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card {
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card {
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card {
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .top-container {
        width: 100%;
    }
}


@media screen and (max-width: 900px) {
    .reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form {
        width: 100%;
    }
}


@media screen and (max-width: 800px) {
    .reschedule-page .reschedule-page-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;
    }
    
    .reschedule-page .reschedule-page-container .reschedule {
        width: 85%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card p {
        font-size: 18px;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card button {
        font-size: 16px;
        width: 35%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card p {
        font-size: 18px;
    }
    
    .reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card button {
        font-size: 16px;
        width: 40%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card p {
        font-size: 18px;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card button {
        font-size: 16px;
        width: 40%;
    }
}

@media screen and (max-width: 700px) {
    .reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        width: 90%;
        padding: 22px;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card p {
        color: white;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 14px;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card button {
        height: 38px;
        width: 100%;
        font-weight: 600;
        font-size: 20px;
        background-color: #ffcc00;
        border: 2px solid #cc9900;
        color: #432e23;
        cursor: pointer;
        border-radius: 18px;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 90%;
        height: 140px;
        padding: 22px;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card p {
        color: white;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 14px;
    }
    
    .reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card button {
        height: 38px;
        width: 100%;
        font-weight: 600;
        font-size: 20px;
        background-color: #ffcc00;
        border: 2px solid #cc9900;
        color: #432e23;
        cursor: pointer;
        border-radius: 18px;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 90%;
        height: 140px;
        padding: 22px;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card p {
        color: black;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 14px;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card button {
        height: 38px;
        width: 100%;
        font-weight: 600;
        font-size: 20px;
    }

    .reschedule-page .reschedule-page-container .reschedule .top-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 34px;
    }

    .reschedule-page .reschedule-page-container .reschedule .top-container .left {
        display: flex;
        flex-direction: column;
    }
    
    .reschedule-page .reschedule-page-container .reschedule .top-container .left h1 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 10px;
    }
    
    .reschedule-page .reschedule-page-container .reschedule .top-container .left h2 {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 34px;
    }

    
}

@media screen and (max-width: 500px) {
    .reschedule-page .reschedule-page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .reschedule-page .reschedule-page-container .reschedule {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .reschedule-page .reschedule-page-container .reschedule .top-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .classtime-card {
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .day-card-container .day-card {
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .timeslot-container .timeslot-card {
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .top-container .back-button {
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form {
        height: 100%;
        margin-bottom: 80px;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form .form-container .input-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .reschedule-page .reschedule-page-container .reschedule .classtimes .confirmation-form .form-container .input-container input {
        margin-bottom: 12px;
        width: 100%;
    }
}